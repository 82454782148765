import { useCallback } from "react";
import { createContext, useContext, useReducer } from "react";
import { SettingsReducer, ACTIONS } from "./SettingsReducer";

const SettingsContext = createContext();

export const CONNECTION_STATES = {
	IDLE: "Idle",
	CONNECTING: "Connecting",
	CONNECTED: "Connected",
	UNAVAILABLE: "Unavailable",
	RESERVATION: "Reservation",
};

const initialState = {
	streamerSettings: {
		serverPath: "ucan-streaming.com",
		uuid: "",
		https: true,
		iceServers: [
			{
				urls: ["stun:stun.ucan-streaming.com:3478"],
				username: "",
				credential: "",
			},
			{
				urls: ["turn:turn.ucan-streaming.com:3478"],
				username: "adminuser",
				credential: "qwerty123456!@#$%^",
			},
		],
	},
	settingsVisible: false,
	connectionState: CONNECTION_STATES.IDLE,
};

export function SettingsProvider(props) {
	const [state, dispatch] = useReducer(SettingsReducer, initialState);

	const loadCache = useCallback(() => {
		dispatch({
			type: ACTIONS.LOAD_CACHE,
		});
	}, [dispatch]);

	const setConnectionStatus = useCallback(
		(newStatus) => {
			dispatch({
				type: ACTIONS.SET_CONNECTION_STATE,
				payload: newStatus,
			});
		},
		[dispatch]
	);
	const setServerPath = useCallback(
		(newPath) => {
			dispatch({
				type: ACTIONS.SET_SERVERPATH,
				payload: newPath,
			});
		},
		[dispatch]
	);
	const setSettingsVisible = useCallback(
		(newState) => {
			dispatch({
				type: ACTIONS.SET_SETTINGS_VISIBLE,
				payload: newState,
			});
		},
		[dispatch]
	);
	const setUuid = useCallback(
		(newUuid) => {
			dispatch({
				type: ACTIONS.SET_UUID,
				payload: newUuid,
			});
		},
		[dispatch]
	);

	const setHttps = useCallback(
		(useHttps) => {
			dispatch({
				type: ACTIONS.SET_HTTPS,
				payload: useHttps,
			});
		},
		[dispatch]
	);

	const setIce = useCallback(
		(iceServers) => {
			dispatch({
				type: ACTIONS.SET_ICE,
				payload: iceServers,
			});
		},
		[dispatch]
	);

	const addIce = useCallback(
		(iceConfig) => {
			dispatch({
				type: ACTIONS.ADD_ICE,
				payload: iceConfig,
			});
		},
		[dispatch]
	);

	const removeIce = (idx) => {
		dispatch({
			type: ACTIONS.REMOVE_ICE,
			payload: idx,
		});
	};

	const sendMessage = useCallback(
		(msg) => {
			if (state.streamerRef === null)
				return console.warn("Streamer instance not set. Can't send message");
			state.streamerRef.current.sendCommand(msg);
		},
		[state.streamerRef]
	);

	const contextValue = {
		state,
		loadCache,
		setConnectionStatus,
		setServerPath,
		setSettingsVisible,
		setUuid,
		setHttps,
		setIce,
		addIce,
		removeIce,
		sendMessage,
	};
	return (
		<SettingsContext.Provider value={contextValue}>
			{props.children}
		</SettingsContext.Provider>
	);
}

const useSettings = () => {
	const context = useContext(SettingsContext);
	if (context === undefined) {
		throw new Error("useSettings must be used within SettingsContext");
	}
	return context;
};
export default useSettings;
