import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SettingsProvider } from "./SettingsProvider";
import { Flowbite } from "flowbite-react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const customTheme = {
	card: {
		root: {
			children: "flex h-full flex-col justify-center gap-2 p-4",
		},
	},
	button: {
		color: {
			info: "text-white bg-sky-400 border border-transparent enabled:hover:bg-sky-500 focus:ring-4 focus:ring-sky-100 dark:bg-sky-300 dark:enabled:hover:bg-cyan-700 dark:focus:ring-cyan-800",
		},
	},
};

root.render(
	<React.StrictMode>
		<SettingsProvider>
			<Flowbite theme={{ theme: customTheme }}>
				<App />
			</Flowbite>
		</SettingsProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
