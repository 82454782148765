import { useEffect, useState } from "react";
import useSettings from "../SettingsProvider";
import {
	Button,
	Card,
	Checkbox,
	Label,
	TextInput,
	Badge,
} from "flowbite-react";
import { Separator } from "./ui/Separator";

export function GamezureSettings() {
	const {
		state,
		setServerPath,
		setUuid,
		setHttps,
		setSettingsVisible,
		addIce,
		removeIce,
	} = useSettings();

	const [localIce, setLocalIce] = useState({
		urls: [""],
		username: "",
		credential: "",
	});
	// console.log(state.streamerSettings.iceServers);
	const resetLocalIce = () => {
		setLocalIce({
			urls: [""],
			username: "",
			credential: "",
		});
	};

	return (
		<Card className='fixed left-2 bottom-2 '>
			<div
				className={`cursor-pointer ${
					state.settingsVisible && "absolute"
				} top-4 right-4`}
				onClick={() => setSettingsVisible(!state.settingsVisible)}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='h-6 w-6'
					fill='none'
					viewBox='0 0 24 24'
					stroke='currentColor'
					strokeWidth={2}>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z'
					/>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
					/>
				</svg>
			</div>
			{state.settingsVisible && (
				<form className='flex flex-col space-y-2'>
					<div className='flex flex-col space-y-2'>
						<Label>Server address</Label>
						<div className='flex space-x-2 items-center'>
							<TextInput
								type='text'
								value={state.streamerSettings.serverPath}
								onChange={(e) => {
									e.preventDefault();
									setServerPath(e.target.value);
								}}
							/>
							<div>
								<Label className='flex items-center space-x-2'>
									<Checkbox
										type='checkbox'
										checked={state.streamerSettings.https}
										onChange={() => setHttps(!state.streamerSettings.https)}
										className=''
									/>
									<span className='text-xs'>https</span>
								</Label>
							</div>
						</div>
					</div>
					<div className='flex flex-col space-y-2'>
						<Label className='font-semibold'>ICE settings</Label>
						{state.streamerSettings.iceServers.map((ice, idx) => {
							return (
								<div key={idx} className='flex justify-between items-center'>
									<Badge>
										{/* <span className='font-semibold'>Urls:</span> */}
										<span>{ice.urls.join(", ")}</span>
									</Badge>

									<Button
										color='warning'
										size='xs'
										onClick={(e) => {
											removeIce(idx);
										}}>
										Clear
									</Button>
								</div>
							);
						})}
						<div className=''>
							<div
								className='grid grid-cols-2 gap-1 items-center'
								style={{ gridTemplateColumns: " 40px 1fr" }}>
								<Label className='w-10'>Urls</Label>
								<TextInput
									type='text'
									value={localIce.urls.join(",")}
									onChange={(e) => {
										e.preventDefault();
										setLocalIce((ice) => ({
											...ice,
											urls: e.target.value.split(","),
										}));
									}}
								/>
								<Label>User</Label>
								<TextInput
									type='text'
									value={localIce.username}
									onChange={(e) => {
										e.preventDefault();
										setLocalIce((ice) => ({
											...ice,
											username: e.target.value,
										}));
									}}
								/>
								<Label>Pass</Label>
								<TextInput
									type='text'
									value={localIce.credential}
									onChange={(e) => {
										e.preventDefault();
										setLocalIce((ice) => ({
											...ice,
											credential: e.target.value,
										}));
									}}
								/>
							</div>
							<Button
								outline
								size='xs'
								className='w-full my-2'
								onClick={() => {
									addIce(localIce);
									resetLocalIce();
								}}>
								Add
							</Button>
						</div>
					</div>
					<div className='flex flex-col space-y-2'>
						<Label className='font-semibold'>UUID</Label>
						<TextInput
							type='text'
							value={state.streamerSettings.uuid}
							onChange={(e) => {
								e.preventDefault();
								setUuid(e.target.value);
							}}
						/>
						<div className='italic font-xs'>Leave empty to auto-fetch</div>
					</div>
				</form>
			)}
		</Card>
	);
}
