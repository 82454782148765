export const ACTIONS = {
	SET_CONNECTION_STATE: "SET_CONNECTION_STATE",
	SET_SERVERPATH: "SET_SERVERPATH",
	SET_UUID: "SET_UUID",
	SET_HTTPS: "SET_HTTPS",
	SET_SETTINGS_VISIBLE: "SET_SETTINGS_VISIBLE",
	SET_ICE: "SET_ICE",
	ADD_ICE: "ADD_ICE",
	REMOVE_ICE: "REMOVE_ICE",
	LOAD_CACHE: "LOAD_CACHE",
};

function saveToCache(state) {
	localStorage.setItem("connection-settings", JSON.stringify(state));
}

export function SettingsReducer(state, action) {
	switch (action.type) {
		case ACTIONS.LOAD_CACHE:
			const cachedSettings = localStorage.getItem("connection-settings");
			if (cachedSettings && cachedSettings !== "") {
				const cachedSettings = JSON.parse(
					localStorage.getItem("connection-settings")
				);
				// Just to be sure, remove uuid
				delete cachedSettings.uuid;

				const copy = {
					...state,
					streamerSettings: {
						...state.streamerSettings, // For backwards compatibility
						...cachedSettings,
					},
				};
				console.log("Settings loaded from cache");
				return copy;
			}
			return state;

		case ACTIONS.SET_CONNECTION_STATE:
			return {
				...state,
				connectionState: action.payload,
			};
		case ACTIONS.SET_SERVERPATH:
			saveToCache({ ...state.streamerSettings, serverPath: action.payload });
			return {
				...state,
				streamerSettings: {
					...state.streamerSettings,
					serverPath: action.payload,
				},
			};
		case ACTIONS.SET_SETTINGS_VISIBLE:
			return {
				...state,
				settingsVisible: action.payload,
			};
		case ACTIONS.SET_UUID:
			return {
				...state,
				streamerSettings: {
					...state.streamerSettings,
					uuid: action.payload,
				},
			};
		case ACTIONS.SET_ICE:
			saveToCache({
				...state.streamerSettings,
				iceServers: action.payload,
			});

			return {
				...state,
				streamerSettings: {
					...state.streamerSettings,
					iceServers: action.payload,
				},
			};
		case ACTIONS.ADD_ICE:
			saveToCache({
				...state.streamerSettings,
				iceServers: [...state.streamerSettings.iceServers, action.payload],
			});

			return {
				...state,
				streamerSettings: {
					...state.streamerSettings,
					iceServers: [...state.streamerSettings.iceServers, action.payload],
				},
			};
		case ACTIONS.REMOVE_ICE:
			const copy = { ...state.streamerSettings };
			copy.iceServers.splice(action.payload, 1);

			saveToCache(copy);

			return {
				...state,
				streamerSettings: copy,
			};
		case ACTIONS.SET_HTTPS:
			saveToCache({
				...state.streamerSettings,
				https: action.payload,
			});

			return {
				...state,
				streamerSettings: {
					...state.streamerSettings,
					https: action.payload,
				},
			};

		default:
			return state;
	}
}
