// import "./App.css";
import { useEffect, useRef } from "react";
import { GamezureSettings } from "./components/GamezureSettings";
import useSettings from "./SettingsProvider";
import { VersionNumber } from "./utils";
import { StreamingPlayer } from "./components/StreamingPlayer";
import { useState } from "react";
import { Button, Card } from "flowbite-react";
/*
function uuid4() {
	var temp_url = URL.createObjectURL(new Blob());
	var uuid = temp_url.toString();
	URL.revokeObjectURL(temp_url);
	return uuid.split(/[:/]/g).pop().toLowerCase(); // remove prefixes
}
*/

function App() {
	const didMountRef = useRef(false);

	const { state, loadCache } = useSettings();
	const [showStreamer, setShowStreamer] = useState(false);

	useEffect(() => {
		loadCache();
	}, [loadCache]);

	useEffect(() => {
		// Check if this is not the first run
		if (!didMountRef.current) {
			didMountRef.current = true;
			return;
		}
	}, [state.settings]);

	return (
		<div className="overflow-hidden w-screen h-screen bg-gradient-to-r from-gray-100 to-gray-300">
			<Card className="fixed z-10 top-2 left-2 p-0">
				<h1 className="text-2xl font-bold tracking-tight text-sky-400 dark:text-white ">
					Azure cloud client
				</h1>
				<div className="text-xs text-gray-400">
					<VersionNumber />
				</div>
			</Card>
			<main className="w-full h-full">
				{!showStreamer && (
					<Button
						gradientDuoTone="purpleToBlue"
						outline
						size="xl"
						className=" absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 "
						onClick={() => setShowStreamer(true)}>
						Start
					</Button>
				)}
				{showStreamer && (
					<StreamingPlayer className="bg-gradient-to-r from-gray-100 to-gray-300 w-full h-full" />
				)}
				{/* <GamezureClient className='bg-gradient-to-r from-gray-100 to-gray-300 w-full h-full' /> */}
			</main>
			<GamezureSettings />
		</div>
	);
}

export default App;
