import { useState } from "react";
import { CONNECTION_STATES, useStreamer } from "../libs/streaming/useStreamer";
import { useEffect } from "react";
import { Button, Badge, Card, Label, TextInput } from "flowbite-react";

import useSettings from "../SettingsProvider";
import { Separator } from "./ui/Separator";

const CONNECTION_STATE_LABELS = {
	IDLE: "gray",
	CONNECTING: "info",
	CONNECTED: "success",
	UNAVAILABLE: "failure",
	RESERVATION: "indigo",
};

export const StreamingPlayer = (props) => {
	const [receivedMessages, setReceivedMessages] = useState([]);
	const [showIncomingPing, setShowIncomingPing] = useState(false);
	const [messageToSend, setMessageToSend] = useState("");
	const [uiVisible, setUiVisible] = useState({
		outgoing: false,
		incoming: false,
	});
	const { state } = useSettings();

	const { streamerStatus, videoStream, sendMessage, reconnect } = useStreamer({
		serverPath: state.streamerSettings.serverPath,
		iceServers: state.streamerSettings.iceServers,
		useHttps: state.streamerSettings.https,
		messageCallback: (msg) => {
			setReceivedMessages((prev) => [
				...prev,
				`${new Date().toLocaleTimeString()}: ${msg}`,
			]);
			setShowIncomingPing(true);
		},
		onReady: () => {},
		onDisconnect: () => {},
	});
	const sendCommand = () => {
		sendMessage(messageToSend);
	};

	const sendResolutionUpdate = (w, h) => {
		sendMessage(`RESOLUTION_UPDATE:${w}x${h}`);
	};

	useEffect(() => {
		if (streamerStatus === CONNECTION_STATES.DISCONNECTED) {
			setReceivedMessages([]);
		}
	}, [streamerStatus]);

	return (
		<div className={props.className}>
			<div className="relative w-full h-full">
				<div id="player" ref={videoStream} className="w-full h-full"></div>
				{streamerStatus === CONNECTION_STATES.DISCONNECTED && (
					<Button
						gradientDuoTone="purpleToBlue"
						outline
						size="xl"
						className=" absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 "
						onClick={reconnect}>
						Reconnect
					</Button>
				)}
				<Badge
					className="fixed bottom-2 right-2"
					color={CONNECTION_STATE_LABELS[streamerStatus.toUpperCase()]}>
					{streamerStatus}
				</Badge>

				<Card className="bg-white absolute right-20 top-2 flex flex-col space-y-1">
					<div
						className={`cursor-pointer ${
							uiVisible.incoming ? "absolute top-4 right-4" : "relative"
						} `}
						onClick={() => {
							setUiVisible({ incoming: !uiVisible.incoming, outgoing: false });
							setShowIncomingPing(false);
						}}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
							className="h-6 w-6"
							fill="currentColor"
							stroke="none"
							strokeWidth={2}>
							<path d="M121 32C91.6 32 66 52 58.9 80.5L1.9 308.4C.6 313.5 0 318.7 0 323.9V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V323.9c0-5.2-.6-10.4-1.9-15.5l-57-227.9C446 52 420.4 32 391 32H121zm0 64H391l48 192H387.8c-12.1 0-23.2 6.8-28.6 17.7l-14.3 28.6c-5.4 10.8-16.5 17.7-28.6 17.7H195.8c-12.1 0-23.2-6.8-28.6-17.7l-14.3-28.6c-5.4-10.8-16.5-17.7-28.6-17.7H73L121 96z" />
						</svg>
						{showIncomingPing && !uiVisible.incoming && (
							<span className="block rounded-full bg-red-700 w-2 h-2 -top-1 -right-1 absolute"></span>
						)}
					</div>
					{uiVisible.incoming && (
						<div
							style={{ minHeight: 24 }}
							className="text-xs max-h-72 overflow-y-auto divide-y w-80">
							{receivedMessages.map((msg, i) => (
								<p className="py-2" key={i}>
									{msg}
								</p>
							))}
						</div>
					)}
				</Card>
				<Card className="bg-white absolute right-2 top-2 flex flex-col space-y-1">
					<div
						className={`cursor-pointer ${
							uiVisible.outgoing && "absolute"
						} top-4 right-4`}
						onClick={() =>
							setUiVisible({ outgoing: !uiVisible.outgoing, incoming: false })
						}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
							className="h-6 w-6"
							fill="currentColor"
							stroke="none"
							strokeWidth={2}>
							<path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z" />
						</svg>
					</div>
					{uiVisible.outgoing && (
						<>
							<Label>Send command</Label>
							<TextInput
								type="text"
								value={messageToSend}
								onChange={(e) => setMessageToSend(e.target.value)}
							/>
							<Button onClick={sendCommand}>Send</Button>

							<Separator />
							<Label>Resolution</Label>

							<Button
								onClick={() => {
									sendResolutionUpdate(1920, 1080);
								}}>
								1920 x 1080
							</Button>
							<Button
								onClick={() => {
									sendResolutionUpdate(window.innerWidth, window.innerHeight);
								}}>
								Browser resolution
							</Button>
						</>
					)}
				</Card>
			</div>
		</div>
	);
};
