import packageJson from "../package.json";
import preval from "preval.macro";
import React from "react";

const buildTimestamp = preval`module.exports = new Date().getTime();`;

const getDateString = () => {
	const lastUpdateMoment = new Date(buildTimestamp);
	const formattedDate = lastUpdateMoment.toLocaleString();

	return formattedDate;
};

const BuildInformation = () => {
	return {
		buildNumber: packageJson.version,
		buildTime: buildTimestamp,
		currentTime: getDateString(),
	};
};

class VersionNumber extends React.PureComponent {
	render() {
		return (
			<span>
				{packageJson.version}
				{"."}
				{buildTimestamp} {"("}
				{getDateString()}
				{")"}
			</span>
		);
	}
}

VersionNumber.propTypes = {};

VersionNumber.defaultProps = {};

export { VersionNumber, BuildInformation };
